<template>
  <div v-if="address && address.id">
    <p class="is-size-6"><b>{{ address.org.code }}</b> - {{ address.short_code }}</p>
    <p class="is-size-7">{{address.company_name || address.org.fullname}}</p>
    <p class="is-size-7">{{ address.address1 }} {{ address.address2 }}</p>
    <!-- <p class="is-size-7"></p> -->
    <p class="is-size-7">{{ address.city }} {{address.state}} {{address.postal}} {{ address.country }}</p>
  </div>
</template>

<script>
export default {
  name: 'AddressView',
  props: {
      address: Object,
  },
  data() {
    return {
    }
  },
  watch:{
  },
  created: function(){
  },
  methods: {
  }
}
</script>