import axios from "axios";
import LocalStorageService from "./LocalStorageService";
import router from "../router";
import store from "@/store";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// if the NODE_ENV during npm run build/serve is prod or dev, use the .env files to set the api url
// else use a hardcoded 5010 port just in case
if (["production", "development"].includes(process.env.NODE_ENV)) {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
} else {
  axios.defaults.baseURL = "http://localhost:5010";
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // const token = localStorageService.getAccessToken();
    if (config.url != "/login") {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
let isReloading = "no";
axios.interceptors.response.use(
  function(response) {
    // console.log(response)
    if (process.env.VUE_APP_VERSION != response.headers["x-app-version"]) {
      // console.log(`axios ${process.env.VUE_APP_VERSION}, ${response.headers['x-app-version']}`)
      if (isReloading != "yes") {
        alert(`Reloading page for website update.`);
      }
      isReloading = "yes";
      location.reload();
    }
    return response;
  },
  function(error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url.includes("/refresh")
    ) {
      if (window.location.pathname != "/login") {
        sessionStorage.setItem("isReloaded", "no");
        router.push("/login");
      }
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/login")
    ) {
      originalRequest._retry = true;
      // const refreshToken = localStorageService.getRefreshToken();
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorageService.getAccessToken();
      return axios.get(`/refresh`).then((res) => {
        if (res.status === 201) {
          // localStorageService.setToken(res.data);
          store.commit("set_token", res.data.access_token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorageService.getAccessToken();
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
