import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import Buefy from "buefy";
//import 'buefy/dist/buefy.css'
import OrganizationCreate from "@/components/master_data/OrganizationCreate";
import Documentation from "@/components/Documentation";
import AddressSearch from "@/components/AddressSearch";
Vue.config.productionTip = true;

Vue.prototype.$http = Axios;
Vue.use(Buefy, {
  defaultIconPack: "fas", // added Font-Awesome icon config
});

Vue.component("OrganizationCreate", OrganizationCreate);
Vue.component("Documentation", Documentation);

Vue.component("AddressSearch", AddressSearch);

const token = localStorage.getItem("access_token");

// Axios.defaults.baseURL = 'http://localhost:5010'
// Axios.defaults.baseURL = process.env.API_URL

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

// eslint-disable-next-line
const vm = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
