<template>
  <div>
    <b-sidebar type="is-light" :fullheight="true" :open.sync="open">
      <div class="p-1">
        <b-menu>
          <b-menu-list label="My Bookmarks">
            <b-menu-item
              icon="link"
              tag="router-link"
              :label="bookmark.linkName"
              :to="{name: bookmark.routeName, params: bookmark.routeParams}"
              v-for="bookmark in $store.getters.bookmarks"
              :key="bookmark.linkName"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
        <hr />
        <div v-if="$store.getters.userHasAllowedRole(['MDEdit','MDView'])">
        <h1 class="sub-title">Find an Address</h1>
        <address-search />
        </div>
        <hr />
        <organization-create v-if="$store.getters.userHasAllowedRole(['MDEdit'])"></organization-create>
      </div>
      <div class="p-1">
        <p>
        v {{version}}
        </p>
      </div>
    </b-sidebar>
    <b-field>
      <button class="button" @click="open=true">Sidebar</button>
      <p class="control">
                <span class="button is-static">v {{version}}</span>
            </p>
    </b-field>
  </div>
</template>
<script>


export default {
  data() {
    return {
      open: false,
      version: process.env.VUE_APP_VERSION
    };
  },
  // components: {
  //   'sidebar-address-search':AddressSearch
  // }
};
</script>

<style>
</style>