import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { ToastProgrammatic } from "buefy";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: { title: "Home", requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin.vue"),
    meta: { title: "Admin", requiresAuth: true, allowedRoles: ["Admin", "UserAdmin"] },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "Login" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
    meta: { title: "Profile" },
  },
  {
    path: "/set-password",
    name: "set-password",
    component: () => import("@/views/SetPassword.vue"),
    meta: { title: "Set Password" },
  },
  {
    path: "/secure",
    name: "secure",
    component: () => import("@/views/Secure.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/records",
    name: "records",
    component: () => import("@/views/lane_mgmt/Records.vue"),
    meta: {
      title: "Records",
      requiresAuth: true,
      allowedRoles: ["LMView", "LMEdit"],
    },
  },
  {
    path: "/records/:record_id",
    name: "record",
    component: () => import("@/views/lane_mgmt/Record.vue"),
    props: true,
    meta: {
      title: "Record",
      requiresAuth: true,
      allowedRoles: ["LMView", "LMEdit"],
    },
    children: [
      {
        path: "lanes",
        name: "record-lanes",
        component: () => import("@/views/lane_mgmt/record_tabs/LanesList.vue"),
        props: true,
        meta: { title: "Lanes", allowedRoles: ["LMEdit", "LMView"] },
      },
      {
        path: "lanes/:lane_id",
        name: "lane",
        component: () => import("@/views/lane_mgmt/Lane.vue"),
        props: true,
        meta: {
          title: "Lane",
          requiresAuth: true,
          allowedRoles: ["LMView", "LMEdit"],
        },
      },
      {
        path: "segments",
        name: "record-segments",
        component: () => import("@/views/lane_mgmt/record_tabs/SegmentsList.vue"),
        props: true,
        meta: { title: "Segments", allowedRoles: ["LMEdit", "LMView"] },
      },
      {
        path: "segments-lanes-link",
        name: "record-segments-lanes-link",
        component: () => import("@/views/lane_mgmt/record_tabs/LaneSegmentLinksList.vue"),
        props: true,
        meta: { title: "Lanes/Segments Links", allowedRoles: ["LMEdit", "LMView"] },
      },
      {
        path: "segments/:segment_id",
        name: "segment",
        component: () => import("@/views/lane_mgmt/Segment.vue"),
        props: true,
        meta: {
          title: "Segment",
          requiresAuth: true,
          allowedRoles: ["LMView", "LMEdit"],
        },
      },
      {
        path: "versions",
        name: "record-versions",
        component: () => import("@/views/lane_mgmt/record_tabs/VersionsList.vue"),
        props: true,
        meta: { title: "Versions", allowedRoles: ["LMEdit", "LMView"] },
      },
      {
        path: "versions/:version_id",
        name: "version",
        component: () => import("@/views/lane_mgmt/Version.vue"),
        props: true,
        meta: {
          title: "Version",
          requiresAuth: true,
          allowedRoles: ["LMView", "LMEdit"],
        },
      },
      {
        path: "notes",
        name: "record-notes",
        component: () => import("@/views/lane_mgmt/record_tabs/RecordNotes.vue"),
        props: true,
        meta: { title: "Notes", allowedRoles: ["LMEdit", "LMView"] },
      },
      {
        path: "lane-upload",
        name: "record-lane-upload",
        component: () => import("@/views/lane_mgmt/record_tabs/LaneUpload.vue"),
        props: true,
        meta: { title: "Lane Upload", allowedRoles: ["LMEdit"] },
      },
      {
        path: "segment-upload",
        name: "record-segment-upload",
        component: () => import("@/views/lane_mgmt/record_tabs/SegmentUpload.vue"),
        props: true,
        meta: { title: "Segment Upload", allowedRoles: ["LMEdit"] },
      },
      {
        path: "charge-upload",
        name: "record-charge-upload",
        component: () => import("@/views/lane_mgmt/record_tabs/ChargesUpload.vue"),
        props: true,
        meta: { title: "Charge Upload", allowedRoles: ["RMEdit"] },
      },
      {
        path: "bulk-update",
        name: "bulk-update",
        component: () => import("@/views/lane_mgmt/record_tabs/BulkUpdatePage.vue"),
        props: true,
        meta: { title: "Bulk Update", allowedRoles: ["LMEdit"] },
      },
      {
        path: "cargosphere-get-rates",
        name: "cargosphere-get-rates",
        component: () => import("@/components/CargoSphereGetRates.vue"),
        props: true,
        meta: { title: "CargoSphere Search", allowedRoles: ["RMEdit"] },
      },
      {
        path: "events",
        name: "events",
        component: () => import("@/views/lane_mgmt/Events.vue"),
        props: true,
        meta: { title: "Events Dashboard", allowedRoles: ["LMView", "RMView","LMEdit","RMEdit" ] },
      },
    ],
  },
  {
    path: "/organizations",
    name: "organizations",
    component: () => import("@/views/master_data/Organizations.vue"),
    meta: {
      title: "Orgs",
      requiresAuth: true,
      allowedRoles: ["MDView", "MDEdit"],
    },
  },
  {
    path: "/organizations/audit",
    name: "org-address-sync",
    props: true,
    component: () => import("@/views/master_data/OrgAddressSync.vue"),
    meta: {
      title: "Org and Address Audit",
      requiresAuth: true,
      allowedRoles: ["MDView", "MDEdit"],
    },
  },
  {
    path: "/organizations/:id",
    name: "organization",
    props: true,
    component: () => import("@/views/master_data/Organization.vue"),
    meta: {
      title: "Org",
      requiresAuth: true,
      allowedRoles: ["MDView", "MDEdit"],
    },
  },
  {
    path: "/carrier-intel",
    name: "CarrierIntel",
    component: () => import("@/views/carrier_intel/CarrierIntel.vue"),
    meta: {
      title: "CarrierIntel",
      requiresAuth: true,
      allowedRoles: ["CTEdit", "CTView"],
    },
    children: [
      {
        path: "carrier-intel-management",
        name: "carrier-intel-management",
        component: () => import("@/views/carrier_intel/CarrierIntelMgmt.vue"),
        props: true,
        meta: {
          title: "Carrier Intel Management",
          allowedRoles: ["CTEdit", "CTView"],
        },
      },
      {
        path: "carrier-intel-management/:id",
        name: "CarrierEdit",
        props: true,
        component: () => import("@/views/carrier_intel/CarrierEdit.vue"),
        meta: {
          title: "Carrier Edit",
          requiresAuth: true,
          allowedRoles: ["CTEdit", "CTView"],
        },
      },
      {
        path: "domestic-carrier-search",
        name: "domestic-carrier-search",
        component: () => import("@/views/carrier_intel/CarrierSearch.vue"),
        props: true,
        meta: { title: "Domestic Carrier Search", allowedRoles: ["CTEdit", "CTView"], },
      },
      {
        path: "carrier-rate-request",
        name: "carrier-rate-request",
        component: () => import("@/views/carrier_intel/CarrierRateRequest.vue"),
        props: true,
        meta: { title: "Carrier Rate Request", allowedRoles: ["CTEdit", "CTView"], },
      },
      {
        path: "carrier-bulk-upload",
        name: "carrier-bulk-upload",
        component: () => import("@/views/carrier_intel/CarrierBulkUpload.vue"),
        props: true,
        meta: { title: "Carrier Bulk Upload", allowedRoles: ["CTEdit", "CTView"], },
      },
      {
        path: "setting-bulk-upload",
        name: "setting-bulk-upload",
        component: () => import("@/views/carrier_intel/SettingBulkUpload.vue"),
        props: true,
        meta: { title: "Setting Bulk Upload", allowedRoles: ["CTEdit", "CTView"], },
      },
    ],
  },
  {
    path: "/zones",
    name: "zones",
    component: () => import("@/views/locations/Zones.vue"),
    meta: {
      title: "Zones",
      requiresAuth: true,
      allowedRoles: ["LDView", "LDEdit"],
    },
  },
  {
    path: "/zones/:id",
    name: "zone",
    component: () => import("@/views/locations/Zone.vue"),
    props: true,
    meta: {
      title: "Zone",
      requiresAuth: true,
      allowedRoles: ["LDView", "LDEdit"],
    }
  },
    {
      path: "/unlocos",
      name: "unlocos",
      component: () => import("@/views/locations/Unlocos.vue"),
      meta: {
        title: "Unlocos",
        requiresAuth: true,
        allowedRoles: ["LDView", "LDEdit"],
      },
    },
  {
    path: "/data-integrity",
    name: "data-integrity",
    component: () => import("@/views/integrity/DataIntegrity.vue"),
    meta: {
      title: "DI",
      requiresAuth: true,
      allowedRoles: ["DIView", "DIEdit"],
    },
  },
  {
    path: "/validator-management",
    name: "validator-management",
    component: () => import("@/views/integrity/ValidatorManagement.vue"),
    meta: {
      title: "DI Admin",
      requiresAuth: true,
      allowedRoles: ["DIAdmin"],
    },
  },
  {
    path: "/bulk-editing",
    name: "bulk-editing",
    component: () => import("@/views/admin/BulkEditing.vue"),
    meta: {
      title: "Admin Bulk Editing",
      requiresAuth: true,
      allowedRoles: ["Admin"],
    },
  },
  {
    path: "/testing",
    name: "testing",
    component: () => import("@/views/admin/TestingPage.vue"),
    meta: {
      title: "Testing",
      requiresAuth: true,
      allowedRoles: ["Admin"],
    },
  },
  {
    path: "/CO2-Filters",
    name: "CO2-Filters",
    component: () => import("@/views/lane_mgmt/CO2Filters.vue"),
    meta: {
      title: "CO2 Filters",
      requiresAuth: true,
      allowedRoles: ["CO2:Edit"],
    },
  },
  {
    path: "/manifests",
    name: "manifests",
    component: () => import("@/views/reports/Manifests.vue"),
    meta: {
      title: "Consol Manifests",
      requiresAuth: true,
      allowedRoles: ["DOCS:MAN"],
    },
  },
  {
    path: "/e-a-report",
    name: "e-a-report",
    component: () => import("@/views/reports/EAReport.vue"),
    meta: {
      title: "E & A Report",
      requiresAuth: true,
      allowedRoles: ["DOCS:E&A"],
    },
  },
  {
    path: "/ap-vendor-invoice",
    name: "ap-vendor-invoice",
    component: () => import("@/views/accounting/APVendorInvoice.vue"),
    meta: {
      title: "AP Vendor Invoice",
      requiresAuth: true,
      allowedRoles: ["ACCT:OAP"],
    },
  },
  {
    path: "/billing-summary-data",
    name: "billing-summary-data",
    component: () => import("@/views/accounting/BillingSummaryData.vue"),
    meta: {
      title: "Billing Summary Data",
      requiresAuth: true,
      allowedRoles: ["ACCT:BSD"],
    },
  },
  {
    path: "/ar-upload",
    name: "ar-upload",
    component: () => import("@/views/accounting/ARUpload.vue"),
    meta: {
      title: "AR Upload",
      requiresAuth: true,
      allowedRoles: ["ACCT:ARU"],
    },
  },
  {
    path: "/eu-trucking",
    name: "eu-trucking",
    component: () => import("@/views/eu_trucking/EuroTrucking.vue"),
    meta: {
      title: "EU Trucking Audit Tool",
      requiresAuth: true,
      allowedRoles: ["ACCT:EUTA"],
    },
    children: [
      {
        path: "euro-trucking-cw-invoice-audit",
        name: "euro-trucking-cw-invoice-audit",
        component: () => import("@/views/eu_trucking/EuroTruckingCWInvoiceAudit.vue"),
        props: true,
        meta: {
          title: "EU Trucking CW Invoice Audit",
          allowedRoles: ["ACCT:EUTA"],
        },
      },
    ]
  },
  
  {
    path: "/rating",
    name: "rating",
    component: () => import("@/views/rating/Rating.vue"),
    meta: {
      title: "Rating",
      requiresAuth: true,
      allowedRoles: ["BST-View"],
    },
  children: [
  {
    path: "/rating/billing-summary-edit/:id",
    name: "billing-summary-edit",
    component: () => import("@/views/rating/BillingSummaryEdit.vue"),
    props: true,
    meta: {
      title: "Billing Summary Edit",
      allowedRoles: ["BST-View"],
    },
  },
  {
    path: "/rating/billing-summary-view/:id",
    name: "billing-summary-view",
    component: () => import("@/views/rating/BillingSummaryView.vue"),
    props: true,
    meta: {
      title: "BillingSummaryView",
      allowedRoles: ["BST-View"],
    },
  },
  {
    path: "/rating/billing-summary-new",
    name: "billing-summary-new",
    component: () => import("@/views/rating/BillingSummaryNew.vue"),
    props: true,
    meta: {
      title: "BillingSummaryNew",
      allowedRoles: ["BST-View"],
    },
  },
  {
    path: "/rating/billing-summary-dashboard",
    name: "billing-summary-dashboard",
    component: () => import("@/views/rating/BillingSummaryDashboard.vue"),
    props: true,
    meta: {
      title: "Billing Summary Dashboard",
      allowedRoles: ["BST-View"],
    },
  },
  {
    path: "/bst/billing-summaries",
    name: "billing-summaries",
    component: () => import("@/views/rating/BillingSummaries.vue"),
    props: true,
    meta: {
      title: "Billing Summaries",
      allowedRoles: ["BST-View"],
    },
  },
]
  },
  {
    path: "/accouting/intracompany-accrual-edit",
    name: "intracompany-accrual-edit",
    component: () => import("@/views/accounting/IntracompanyAccrualEdit.vue"),
    props: true,
    meta: {
      title: "IntracompanyAccrual",
      allowedRoles: ["IAT-View"],
    },
  },
  {
    path: "/intracompany-accrual-tool",
    name: "intracompany-accrual-tool",
    component: () => import("@/views/accounting/IntracompanyAccrualTool.vue"),
    meta: {
      title: "Intracompany Accrual Tool",
      requiresAuth: true,
      allowedRoles: ["IAT-View"],
    },
  children: [
  {
    path: "/accounting/intracompany-accrual-dashboard",
    name: "intracompany-accrual-dashboard",
    component: () => import("@/views/accounting/IntracompanyAccrualDashboard.vue"),
    props: true,
    meta: {
      title: "Intracompany Accrual Dashboard",
      allowedRoles: ["IAT-View"],
    },
  },
  {
    path: "/accounting/intracompany-accruals",
    name: "intracompany-accruals",
    component: () => import("@/views/accounting/IntracompanyAccruals.vue"),
    props: true,
    meta: {
      title: "Intracompany Accruals",
      allowedRoles: ["IAT-View"],
    },
  },
]
  },
  {
    path: "/rating/billing-summary-tool-settings",
    name: "BillingSummaryToolSettings",
    component: () => import("@/views/rating/BillingSummaryToolSettings.vue"),
    meta: {
      title: "BillingSummaryToolSettings",
      requiresAuth: true,
      allowedRoles: ["BST-View","IAT-View"],
    },
  },
  {
    path: "/caplink",
    name: "caplink",
    component: () => import("@/views/caplink/Caplink.vue"),
    meta: {
      title: "Caplink",
      requiresAuth: true,
      allowedRoles: ["CLEdit", "CLView", "CLMasterData", "LPTEdit"],
    },
    children: [
      {
        path: "shipment-dashboard",
        name: "shipment-dashboard",
        component: () => import("@/views/caplink/ShipmentDashboard.vue"),
        props: true,
        meta: {
          title: "CL Dashboard",
          allowedRoles: ["CLEdit", "CLView", "LPTEdit"],
        },
      },
      {
        path: "shipment-entry",
        name: "shipment-entry",
        component: () => import("@/views/caplink/ShipmentEntry.vue"),
        props: true,
        meta: { title: "CL Shipment Entry", allowedRoles: ["CLBook"] },
      },
      {
        path: "lanes-dashboard",
        name: "lanes-dashboard",
        component: () => import("@/views/caplink/LanesDashboard.vue"),
        props: true,
        meta: { title: "Lanes Dashboard", allowedRoles: ["CLMasterData"] },
      },
      {
        path: "master-data",
        name: "master-data",
        component: () => import("@/views/caplink/MasterData.vue"),
        props: true,
        meta: { title: "CL Master Data", allowedRoles: ["CLMasterData"] },
      },
      {
        path: "shipment-edit/:shipment_number",
        name: "shipment-edit",
        component: () => import("@/views/caplink/ShipmentEdit.vue"),
        props: true,
        meta: { title: "CL Edit", allowedRoles: ["CLEdit", "CLView"] },
      },
      {
        path: "load-planning",
        name: "load-planning",
        component: () => import("@/views/caplink/load_planning/LoadPlanning.vue"),
        props: true,
        meta: {
          title: "LoadPlanning Dashboard",
          allowedRoles: ["LPTEdit"],
        },
      },
      {
        path: "load-plan-edit/new",
        name: "load-plan-new",
        component: () => import("@/views/caplink/load_planning/LoadPlanEdit.vue"),
        props: true,
        meta: {
          title: "New LoadPlan",
          allowedRoles: ["LPTEdit"],
        },
      },
      {
        path: "load-plan-edit/:load_plan_name/:id/v:loadplan_version",
        name: "load-plan-edit",
        component: () => import("@/views/caplink/load_planning/LoadPlanEdit.vue"),
        props: true,
        meta: {
          title: "Edit LoadPlan",
          allowedRoles: ["LPTEdit"],
        },
      },
    ],
  },

  {
    path: "/edi",
    name: "EDI",
    component: () => import("@/views/EDI/EDI.vue"),
    meta: {
      title: "EDI",
      requiresAuth: true,
      allowedRoles: ["EDIRecon", "EDIBookings"],
    },
    children: [
      {
        path: "recon-dashboard",
        name: "recon-dashboard",
        component: () => import("@/views/EDI/ReconDashboard.vue"),
        props: true,
        meta: { title: "EDI Recon", allowedRoles: ["EDIRecon"] },
      },
      {
        path: "bookings-dashboard",
        name: "bookings-dashboard",
        component: () => import("@/views/EDI/BookingsDashboard.vue"),
        props: true,
        meta: { title: "EDI Bookings", allowedRoles: ["EDIBookings"] },
      },
    ],
  },
  {
    path: "/quality",
    name: "Quality",
    component: () => import("@/views/quality/Quality.vue"),
    meta: {
      title: "Quality",
      requiresAuth: true,
      allowedRoles: ["QUAL-View"],
    },
    children: [
      {
        path: "quality-dashboard",
        name: "quality-dashboard",
        component: () => import("@/views/quality/QualityDashboard.vue"),
        props: true,
        meta: { title: "Quality Dashboard", allowedRoles: ["QUAL-View"] },
   
      },
    ],
  },
  {
    path: "/cw-digest",
    name: "CW Billing Digest Tool",
    component: () => import("@/views/cw_digest/CWDigestDashboard.vue"),
    meta: {
      title: "CW Billing Digest Tool",
      requiresAuth: true,
      allowedRoles: ["ACCT:DIG"],
    },
  },
  {
    path: "/co2-emissions",
    name: "CO2 Emissions Tool",
    component: () => import("@/views/lane_mgmt/CO2Emissions.vue"),
    meta: {
      title: "CO2 Emissions Tool",
      requiresAuth: true,
      allowedRoles: ["LMView","LMEdit"],
    },
  },
  {
    path: "/program-editing",
    name: "program-editing",
    component: () => import("@/views/lane_mgmt/ProgramMgmt.vue"),
    meta: {
      title: "Programs",
      requiresAuth: true,
      allowedRoles: ["PROG:View","PROG:Edit"],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function setPageTitle(to) {
  const nextPageTitle =
    to.meta && to.meta.title ? `OtherWise - ${to.meta.title}` : "OtherWise";
  document.title = nextPageTitle;
}

router.beforeEach(async (to, from, next) => {
  await store.dispatch("checkVersion");

  if (!store.getters.token && localStorage.getItem("access_token")) {
    store.commit("set_token", localStorage.getItem("access_token"));
  }

  if (to.meta.requiredRole) {
    try {
      if (!store.getters.isLoggedIn) {
        await store.dispatch("refresh");
      }
    } catch {
      this.$store.dispatch("logout");
    }
    if (
      store.getters.isLoggedIn &&
      (store.getters.userRoles.includes(to.meta.requiredRole) ||
        store.getters.userRoles.includes("Admin"))
    ) {
      setPageTitle(to);
      next();
    } else {
      ToastProgrammatic.open({
        message: "Unauthorized! code: 100",
        type: "is-danger",
      });
      next(false);
    }
  } else if (to.meta.allowedRoles) {
    if (!store.getters.isLoggedIn) {
      await store.dispatch("refresh");
    }
    if (
      store.getters.isLoggedIn &&
      store.getters.userHasAllowedRole(to.meta.allowedRoles) //Admin is implicit
    ) {
      setPageTitle(to);
      next();
    } else {
      ToastProgrammatic.open({
        message: "Unauthorized! code: 200",
        type: "is-danger",
      });
      next(false);
    }
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn) {
      try {
        await store.dispatch("refresh");
        next();
      } catch (err) {
        ToastProgrammatic.open({ message: "Please Login", type: "is-danger" });
        from.path === "/login"
          ? next(false)
          : sessionStorage.setItem("isReloaded", "no");
        next("/login");
      }
    } else {
      setPageTitle(to);
      next();
    }
  } else {
    setPageTitle(to);
    next();
  }
});

export default router;