<template>
  <span>
    <b-tooltip :label="'Open Documentation modal for ' + slug" type="is-info" size="is-small" :position="isLeftSidebar ? 'is-right' : 'is-top'" multilined>
      <b-button
        :class="'button nomargin is-info ' + button_size"
        @click="showModal"
        title="Open Documentation"
      >
        <i class="fas fa-info"></i>
      </b-button>
    </b-tooltip>
    <b-modal
      :active.sync="isDocsModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      can-cancel
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="width:auto">
          <span v-html="md" class="ignore-css content"></span>
        </section>
      </div>
    </b-modal>
  </span>
</template>

<script>
import {marked} from "marked";
export default {
  name: "Documentation",
  props: {
    comp: String,
    slug: String,
    button_size: {type:String, default:'is-small'}
  },
  data() {
    return {
      isDocsModalActive: false,
      md: null,
      isLeftSidebar: this.$parent.$parent.$options.name == 'BSidebar'
    };
  },
  methods: {
    getMd: async function() {
      const response = await fetch(`/docs/${this.comp}/${this.slug}.md`)
      return marked(await response.text());
    },
    showModal: async function() {
      this.md = await this.getMd()
      this.isDocsModalActive = true
    }
  },
  
};
</script>

<style scoped>
.ignore-css {
  all: unset;
}

span {
  text-align: left;
}

</style>