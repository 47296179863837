import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/backend/axios'
// import LocalStorageService from '../backend/LocalStorageService'
import jwtDecode from 'jwt-decode'
import laneMgmtStore from '@/store/laneMgmtStore.js'
import carrierIntelToolStore from '@/store/carrierIntelToolStore.js'
import constantsStore from '@/store/constantsStore.js'


Vue.use(Vuex)

// const BASE_URL = 'http://localhost:5010'
// const BASE_URL = process.env.API_URL

// const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://bridgedev.toclogintl.com/api' : 'http://localhost:5010'

// axios.interceptors.response.use(response => response, async (error) => {
//   if (error.response.status !== 401) {
//     return Promise.reject(error);
//   }
// })

function userHasAllowedRoleBool(userRoles, allowedRoles) {
  if (!userRoles || userRoles.length === 0) return false
  if (!allowedRoles || allowedRoles.length === 0) return false
  // check if any of the user's roles are in the list of allowed roles
  try {
    
    if (allowedRoles.filter(x => userRoles.includes(x)).length > 0) {
      return true
    } else {
      return false
    }
  } catch (error) {
    alert('filter',allowedRoles,typeof(allowedRoles))
    return false
  }
}

export default new Vuex.Store({
  modules:{
    laneMgmtStore,
    carrierIntelToolStore,
    constantsStore,
  },
  state: {
    status: 'unknown',
    access_token: null, // will be null of not found
    user: {
      id:null,
      roles: [],
      username: null
      },
    tokenExpiresAt: null,
    rls: null,
    exp: null,
    showDocs: true,
    omnisearchFilters: {
      'RecordsList': JSON.parse(localStorage.getItem('filtersets_RecordsList')) ?? {},
      'LanesList': JSON.parse(localStorage.getItem('filtersets_LanesList')) ?? {}, 
      'SegmentsList': JSON.parse(localStorage.getItem('filtersets_SegmentsList')) ?? {},
      'OrgsList': JSON.parse(localStorage.getItem('filtersets_OrgsList')) ?? {}, 
      'DataIntegrity': JSON.parse(localStorage.getItem('filtersets_DataIntegrity')) ?? {},
      'Caplink': JSON.parse(localStorage.getItem('filtersets_Caplink')) ?? {},
      'CarriersList': JSON.parse(localStorage.getItem('filtersets_CarriersList')) ?? {}
    },
    bookmarks: JSON.parse(localStorage.getItem('bookmarks')) ?? [],
    appVersionLastCheckedTime: null
  },
  getters: {
    isLoggedIn: state => !!state.access_token && state.tokenExpiresAt - Date.now()/1000 > 0,
    authStatus: state => state.status,
    tokenExpiresAt: state => state.tokenExpiresAt,
    userRoles: state => state.user ? state.user.roles : [],
    token: state => state.access_token,
    userHasAllowedRole: state => allowedRoles => userHasAllowedRoleBool(state.user.roles, allowedRoles),
    userIsAdmin: state => state.user.roles.includes('Admin'),
    userIsUserAdmin: state => state.user.roles.includes('UserAdmin'),
    bookmarks: state => state.bookmarks,
    omnisearchFilters: state => stringKey => (stringKey in state.omnisearchFilters) ? state.omnisearchFilters[stringKey] : null,
  }
  ,

  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state) {
      state.status = 'success'
    },
    load_profile(state, user) {
      state.user.username = user.username
      state.user.id = user.id
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = 'logged out'
      state.access_token = null
      state.user = {roles:[]}
      state.tokenExpiresAt = null
    },
    set_expiresAt(state, expTs) {
      state.tokenExpiresAt = expTs
    },
    set_token(state, token) {
      localStorage.setItem('access_token', token)
      state.access_token = token
      const exp = jwtDecode(token).exp
      const decodedToken = jwtDecode(token)

      state.tokenExpiresAt = exp
      state.exp = decodedToken.exp
      state.rls = decodedToken.rls
      state.user.roles = decodedToken.rls.split(',')

    },
    add_bookmark(state, bookmark) {
      state.bookmarks = [bookmark, ...state.bookmarks]
      localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks))
    },
    remove_bookmark(state, bookmarkName) {
      state.bookmarks = state.bookmarks.filter(b => b.linkName != bookmarkName)
      localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks))
    },
    change_docs_visibility(state) {
      state.showDocs = !state.showDocs
    },
    save_filterset(state, data) {
      if (!state.omnisearchFilters[data.key]){
        state.omnisearchFilters[data.key] = {}
      }
      Vue.set(state.omnisearchFilters[data.key], data.setName , data.filters)

      localStorage.setItem('filtersets_'+data.key, JSON.stringify(state.omnisearchFilters[data.key]))
    },
    remove_filterset(state, data){
      if (!state.omnisearchFilters[data.key]){
        state.omnisearchFilters[data.key] = {}
      }
      Vue.delete(state.omnisearchFilters[data.key], data.setName)
      localStorage.removeItem('filtersets_'+data.key)
    },
    set_app_version_lasted_checked_time(state){
      state.appVersionLastCheckedTime = Date.now()/1000
    }
  },
  actions: {
    async login({ commit }, userData) {
      commit('auth_request')
      try {
        const resp = await axios.post(`/login`, userData)
        const token = resp.data.access_token
        const user = resp.data.user
        commit('set_token', token)
        commit('load_profile', user)
        commit('auth_success')
      }
      catch (err) {
        commit('auth_error')
        throw 'An error occurred during login.'
      }
    },
    async checkToken({ commit, state }) {

      if (localStorage.getItem('access_token') === null) {
        throw 'no token'
      }
      if (state.access_token === null || state.tokenExpiresAt === null) {
        const token = localStorage.getItem('access_token')
        commit('set_token', token)
      }

    },
    async checkVersion({commit, state}){
      if(!state.appVersionLastCheckedTime){
        commit('set_app_version_lasted_checked_time')
      }
      if(state.appVersionLastCheckedTime < Date.now()/1000 - 60*60){
        const resp = await axios.get('/version')
        const appVersion = resp.data.version
        if (process.env.VUE_APP_VERSION != appVersion){
          console.log(`vuex ${process.env.VUE_APP_VERSION}, ${appVersion}`)
          alert(`Reloading page for website update.`)
          location.reload()
        } else {
          commit('set_app_version_lasted_checked_time')
        }
      }
    },
    async refresh({ dispatch, commit }) {
      try{

        const resp = await axios.get(`/refresh`)
        const token = resp.data.access_token
        // LocalStorageService.setToken(token);
        commit('set_token', token)
        await dispatch('getUser')
        // const exp = jwtDecode(token).exp / 1000
        // commit('set_expiresAt', exp)
      }
      catch(err){
        throw "Couldn't refresh token :("

      }

    },
    async getUser({ commit, state }) {
      try {

        if (state.access_token === null) {
          if (localStorage.getItem('access_token') === null) {
            throw 'no token'
          }
          const token = localStorage.getItem('access_token')
          commit('set_token', token)
        }
        const resp = await axios.get(`/me`)
        const user = resp.data.user
        commit('load_profile', user)
      }
      catch {
        // alert("can't get user")
      }
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('access_token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
})
