<template>
  <div>
    <div v-if="withoutSearch == false">
      <div v-if="!override && address">
        <b-field
          :label="!in_tablesearch ? label : ''"
          label-position="on-border"
        >
          <b-autocomplete
            v-if="!withoutSearch"
            v-bind:selected="searchInput"
            :data="searchResults"
            :placeholder="
              searchInput && searchInput.org
                ? searchInput.org.code
                : 'Search...'
            "
            field="searchInput.org.code"
            :loading="isFetching"
            @typing="getAsyncData"
            @select="(option) => (searchInput = option ? option : {})"
          >
            <template slot-scope="props">
              <p class="is-size-6">
                <b>{{ props.option.org.code }}</b>
                - {{ props.option.short_code }}
              </p>
              <p class="is-size-7">
                {{ props.option.company_name || props.option.org.fullname }}
              </p>
              <p class="is-size-7">{{ props.option.address1 }}</p>
              <p class="is-size-7">{{ props.option.address2 }}</p>
              <p class="is-size-7">
                {{ props.option.city }} {{ props.option.state }}
                {{ props.option.postal }} {{ props.option.country }}
              </p>
            </template>
            <template slot="empty">No results</template>
          </b-autocomplete>
        </b-field>
      </div>
      <address-view
        :address="searchInput"
        v-if="!in_tablesearch && searchInput"
      ></address-view>
    </div>
    <span v-if="!in_tablesearch && withoutSearch == true">
      <template>
        <p class="is-size-5 nomargin">{{ label }}</p>
        <hr class="nomargin-whatsoever" />
        <div v-if="address && address.org">
          <p class="is-size-6 nomargin">
            <b>{{ address.org.code }}</b>
            - {{ address.short_code }}
          </p>
          <p class="is-size-6 nomargin">
            {{ address.company_name || address.org.fullname }}
          </p>
          <p class="is-size-6 nomargin">{{ address.address1 }}</p>
          <p class="is-size-6 nomargin">{{ address.address2 }}</p>
          <p class="is-size-6 nomargin">
            {{ address.city }} {{ address.state }} {{ address.postal }}
            {{ address.country }}
          </p>
        </div>
        <div v-else-if="manual">
          <p class="is-size-6 nomargin">
            <b>{{ manual }}</b>
          </p>
        </div>
        <div v-else>
          <p class="is-size-6 nomargin">
            <b>N/A</b>
          </p>
        </div>
      </template>
      <template slot="empty">No results</template>
    </span>
  </div>
</template>

<script>
import axios from "@/backend/axios";
import { debounce } from "lodash";
import AddressView from "@/components/AddressView";
export default {
  name: "AddressSearch",
  props: {
    address: Object,
    addresstype: String,
    label: String,
    manual: String,
    withoutSearch: { type: Boolean, default: false },
    in_tablesearch: { type: Boolean, default: false },
    override: Boolean,
  },
  data() {
    return {
      isFetching: false,
      searchInput: {},
      searchResults: [],
    };
  },
  watch: {
    searchInput: function () {
      if (this.in_tablesearch && this.searchInput != {}) {
        this.$emit("address-selected", this.searchInput);
      } else {
        this.$emit("update:address", this.searchInput);
      }
    },
    address: function () {
      this.searchInput = this.address;
    },
  },
  beforeDestroy: function () {
    this.$emit("update:address", {});
  },
  created: function () {
    this.searchInput = this.address;
  },
  components: {
    AddressView,
  },
  methods: {
    getAsyncData: debounce(async function (searched_code) {
      if (!searched_code.length) {
        this.searchResults = [];
        return;
      }
      try {
        this.isFetching = true;
        const res = await axios.get(
          `/addresses?address_type=${this.addresstype}&search_term=${searched_code}`
        );
        this.searchResults = res.data;
      } catch (err) {
        this.searchResults = [];
        this.$buefy.snackbar.open({
          message: `Something went wrong!: ${err.response.data.error}`,
          indefinite: true,
        });
      }
      this.isFetching = false;
    }, 500),
  },
};
</script>
<style>
.nomargin-whatsoever {
  margin: 0;
}
</style>