import axios from "@/backend/axios";

export default {
  state: () => ({
    programs: {},
    container_sizes: {},
    rating_schemas: {}
  }),

  mutations: {
    UPDATE_PROGRAMS(state, payload) {
      state.programs = payload;
    },
    UPDATE_CONTAINER_SIZES(state, payload) {
      state.container_sizes = payload;
    },
    UPDATE_CALCULATORS_DICT(state, payload) {
      state.rating_schemas = payload;
    },
  },
  actions: {
    async setPrograms({ commit }) {
      const res = await axios.get(`/constants`);
      let result = res.data
      commit("UPDATE_PROGRAMS", result.program);
      commit("UPDATE_CONTAINER_SIZES", result.container_sizes);
      commit("UPDATE_CALCULATORS_DICT", result.calculators_dict);
    },
  },
  getters: {
    program_descriptions: function (state) {
      return state.programs;
    },
    container_sizes: function (state) {
      return state.container_sizes;
    },
    rating_schemas: function (state) {
      return state.rating_schemas;
    },
  },
};

