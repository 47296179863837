import axios from '@/backend/axios'

export default {
    state: () => ({
        versions: [],
        sellVersions: [],
        costVersions: [],
        lastRefresh: null,
        record: {
          organizations: [],
        },
        programs:{}
    }),
    getters: {
        versionDesc: state => id => state.versions.filter(v => v.id == id).map(v => { return `ID:${v.id} A:${v.version_alias}` })[0],
        versionToolTip: state => id => state.versions.filter(v => v.id == id).map(v => { return {'version_number':v.version_number, 
        'version_alias': v.version_alias, 'id': v.id, 'versionDesc': `ID:${v.id} A:${v.version_alias}`} })[0],
        // sellVersionDesc: state => id => state.sellVersions.filter(v => v.id == id).map(v => { return `ID:${v.id} A:${v.version_alias}` })[0],
        // costVersionDesc: state => id => state.costVersions.filter(v => v.id == id).map(v => { return `ID:${v.id} A:${v.version_alias}` })[0],
        versionsFromRecordId: state => record_id => state.versions.filter(v => v.record_id == record_id),
        getStateRecord: state => () => {
                return state.record;
        },
        versionFromId: state => id => state.versions.find(v => v.id == id),
        programs: function (state) {
            return state.programs;
        },
    },
    mutations: {
        SET_VERSIONS(state, payload) {
            state.versions = payload
        },
        SET_PROGRAMS(state, payload) {
            state.programs = payload
        },
        setLastRefresh(state) {
            state.lastRefresh = Date.now()
        },
        SET_RECORD(state, payload) {
          state.record = payload;
        },
        // setSellVersions(state, payload) {
        //     state.sellVersions = payload
        // },
        EDIT_RECORD(state, payload) {
            state.record = payload;
        },
        ADD_VERSION(state, payload) {
            state.versions.push(payload)
        },
        DELETE_VERSION(state, payload) {
            let filtered = state.versions.filter(function (version) {
                return version.id != payload.id;
              });
            state.versions = filtered
        }, 
        EDIT_VERSION(state, payload) {
            let foundIndex = state.versions.findIndex(x => x.id == payload.id);
            state.versions[foundIndex] = payload;
        },
    },
    actions: {
        async getVersions({ commit }, record_id) {
            const res = await axios.get(`/records/${record_id}/versions`);
            commit('SET_VERSIONS', res.data.items)
        },
        async getRedisPrograms({ commit }) {
            const res = await axios.get(`/redis_programs`);
            commit('SET_PROGRAMS', res.data)
        },
        addVersion({ commit }, version) {
            commit("ADD_VERSION", version);
        },
        getRecord: async function (context, record_id) {
          const res = await axios.get(`/records/${record_id}`);
          context.commit("SET_RECORD", res.data);       
        },
        editRecord({ commit }, record) {
            commit("EDIT_RECORD", record);
        },
        editVersion({ commit }, version) {
            commit("EDIT_VERSION", version);
        },
        deleteVersion({ commit }, version) {
            commit("DELETE_VERSION", version);
        }
        // async getVersions({ commit, state }) {
        //     if (!state.lastRefresh || state.lastRefresh < (Date.now() - 1000 * 60)) {
        //         commit('setLastRefresh')
        //         const res = await axios.get(`/versions`);
        //         commit('setVersions', res.data.items)
        //     }
        // },
        // async getSellVersions({ commit }, record_id) {
        //     const res = await axios.get(`/records/${record_id}/versions`);
        //     commit('setSellVersions', res.data.items)
        // },
        // async getCostVersions({ commit }, record_id) {
        //     const res = await axios.get(`/records/${record_id}/versions`);
        //     commit('setCostVersions', res.data.items)
        // }
    },
}