
export default  {
    state: () => ({ 
        carriers: []
     }),
    getters: { 
        getCarriers: state => state.carriers
     },
    mutations: { 
        SET_CARRIERS(state, payload){
            state.carriers = payload
            localStorage.setItem('carriers_to_email', JSON.stringify(state.carriers))
        }
     },
     actions: {
        setEmailCarriers ({ commit }, checked) {
          commit('SET_CARRIERS', checked)
        },
        async getEmailCarriers() {           
              return JSON.stringify(localStorage.getItem('carriers_to_email'))     
          },
      }
  }